import React, { createContext } from 'react';

export const AppControlContext = createContext('');

export const AppControlProvider = (props) => {
  const [heroImageTransComplete, setHeroImageTransComplete] = React.useState(false);
  const { children } = props;
  return (
    <AppControlContext.Provider
      value={{
        heroImageTransComplete,
        setHeroImageTransComplete,
      }}
    >
      {children}
    </AppControlContext.Provider>
  );
};
