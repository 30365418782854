// Global text block styles
export const body = {
  color: 'text',
  fontFamily: 'body',
  lineHeight: 'body',
  fontWeight: 'body',
  fontSize: 3,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  "*": {
    mt: 0,
  },
  "p:last-child": {
    mb: 0,
  },
};

export const heading = {
  mb: '0.5em',
};

export const p = {
  fontWeight: 'light',
  fontSize: 4,
  lineHeight: 'body',
  "ul": {
    pl: "1.2em",
  },
  "ul": {
    pl: "1.2em",
  },
};