/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { jsx, Flex, Box, Link as AnchorLink, Text } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import NavigationItems from '../NavigationBlocks/NavigationItems';
import TopBarNavigationMenu from '../NavigationBlocks/NavigationTopbarItems';
import { MenuIcon, FbIcon, IgIcon, TwitterIcon, ForwardArrowIcon, CloseIcon, HeaderLogo } from '../../images/icons';
import * as colors from '../../theme/colors';
import { serializers } from '../../utils/blockMarks';
import BookNowWidgetForm from '../../components/BookNowWidetForm';
import { ThemeHeader } from '../../components';
import { mailTo } from '../../assets/js/utils';
import { AppControlContext } from '../../context/appControl.context';
import theme from '../../theme';
import { NotifyBanner } from '../../blocks';

const HeaderStyles = {
  base: {
    backgroundColor: 'background',
    position: 'relative',
    zIndex: 10,
    height: (theme) => [`${theme.stylesConfig.hearderHeightMobile}px`, null, `${theme.stylesConfig.hearderHeightDesktop}px`],
  },
  inner: {
    justifyContent: 'space-between',
    px: [2, null, 4],
    py: ['4px', null, 0],
    flexDirection: ['column', null, 'row'],
    backgroundColor: 'textInverted',
    alignItems: [null, null, 'center'],
    flexWrap: 'wrap',
    position: 'fixed',
    left: 0,
    transform: 'translateY(-100%)',
    width: '100%',
    transition: 'transform 0.5s ease-in-out 0.3s',
  },
  stickey: {
    transform: 'translateY(0)',
  },
  desktop: {
    display: ['none', null, 'flex'],
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const LogoStyles = {
  base: {
    py: 3,
    px: 2,
    alignItems: 'center',
    'a > svg': {
      width: '67px',
    },
  },
};

const MobileLayout = {
  base: {
    width: '100%',
    display: ['block', null, 'none'],
  },
  wrap: {
    alignItems: 'center',
    justifyContent: 'space-between',
    py: 1,
  },
  logoWrap: {
    alignItems: 'center',
  },
  toggelBtn: {
    pr: 3,
    width: 34,
  },
  HeaderLogo: {
    'a > svg': {
      width: '37px',
    },
  },
};
const reserveHeader = {
  wrap: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imgWrap: {
    maxWidth: 24,
    'a > svg': {
      width: '24px',
    },
  },
  headlineWrapper: {
    textAlign: 'center',
    '> h4': {
      color: 'backgroundOverlay',
      letterSpacing: '1px',
      fontWeight: 'body',
    },
    '> p': {
      fontWeight: 'medium',
    },
  },
};

const HeaderMenu = {
  base: {},
  mainItems: {
    alignItems: 'baseline',
  },
  mobileMenu: {
    flexDirection: ['column', null, 'row'],
    backgroundColor: 'mobileMenu',
    px: 32,
    pt: 32,
    pb: 55,
    height: 'calc(100vh - 84px)',
    overflow: 'auto',
    '-webkitOverflowScrolling': 'touch',
  },
  mainNav: {
    flexDirection: ['column', null, 'row'],
    flexShrink: 0,
  },
  item: {
    py: '6px',
    letterSpacing: 7,
  },
  text: {
    color: 'inherit',
    textTransform: 'inherit',
    letterSpacing: 5,
  },
  link: {
    color: 'textInverted',
    textDecoration: 'none',
  },
  anchor: {
    color: colors.BLACK,
    letterSpacing: 5,
    variant: 'styles.breakWord',
  },
  label: {
    color: colors.BLACK,
    letterSpacing: 5,
    textTransform: 'uppercase',
  },
  social: {
    py: 4,
  },
  socialLinks: {
    border: `1px solid${colors.BLACK}`,
    width: 32,
    height: 32,
    display: 'inline-flex',
    borderRadius: '50%',
    mr: 3,
    variant: 'styles.flexCenter',
  },
  socialIcon: {
    fill: colors.BLACK,
  },

  reserveBtnLink: {
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 4,
    textDecoration: 'none',
    alignItems: 'center',
    textTransform: 'uppercase',
    letterSpacing: '2.56px',
    cursor: 'pointer',
    transition: '0.5s ease-in-out',
    '&:hover': {
      textDecoration: ['none', 'underline'],
      textDecorationThickness: [0, null, '2px'],
    },
  },

  fwdArrow: {
    ml: '8px',
    width: 12,
    transition: '0.5s ease-in-out',
    transform: 'rotate(90deg)',
  },
  rotateFwdArrow: {
    ml: '8px',
    width: 12,
    transition: '0.5s ease-in-out',
    transform: 'rotate(0deg)',
  },
};

export default function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isRevMenuOpen, setRevMenuOpen] = React.useState(false);
  const [headerSticky, setHeaderSticky] = React.useState(false);
  const { heroImageTransComplete } = React.useContext(AppControlContext);
  const headerRef = React.useRef(null);
  let lastScrollTop = 0;
  const handleScroll = () => {
    if (headerRef.current) {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // Down (Scrolling Downward)
        if (isRevMenuOpen) {
          setHeaderSticky(true);
        } else {
          setHeaderSticky(false);
        }
      } else {
        // UP (Scrolling Updward)
        setHeaderSticky(true);
      }
      lastScrollTop = st <= 50 ? 0 : st;
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    handleScroll();
    setHeaderSticky(true);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  React.useEffect(() => {
    if (heroImageTransComplete) setHeaderSticky(true);
  }, [heroImageTransComplete]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    if (typeof window !== 'undefined') {
      if (isRevMenuOpen && window.innerWidth <= parseInt(theme.breakpoints[0], 0)) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'relative';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
    if (isRevMenuOpen) {
      setHeaderSticky(true);
    }
  }, [isRevMenuOpen]);

  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const { _rawSiteContactInformation, _rawSocialInfo } = props?.siteSettings;
  const { mainNavigationLinks, topBarLinks } = props;
  const getSocialIcon = (name, styles) => {
    switch (name) {
      case 'facebook':
        return <FbIcon alt={name} sx={styles} />;
      case 'instagram':
        return <IgIcon alt={name} sx={styles} />;
      case 'twitter':
        return <TwitterIcon alt={name} sx={styles} />;
      default:
        return <FbIcon alt={name} sx={styles} />;
    }
  };
  const handleMenuOpenCloseChange = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const renderSocialAndContactSection = () => {
    const { siteAddress, siteContactNumber, siteEmail } = _rawSiteContactInformation;
    return (
      <>
        <Box>
          <BlockContent blocks={siteAddress} serializers={serializers} />
        </Box>
        <Box sx={HeaderMenu.item}>
          <Text sx={HeaderMenu.label}>Front Desk: </Text>
          <AnchorLink href={`tel:${siteContactNumber}`} sx={HeaderMenu.anchor}>
            {siteContactNumber}
          </AnchorLink>
        </Box>
        <Box sx={HeaderMenu.item}>
          <Text sx={HeaderMenu.label}>Reservations: </Text>
          <AnchorLink href={`tel:${siteContactNumber}`} sx={HeaderMenu.anchor}>
            {siteContactNumber}
          </AnchorLink>
        </Box>
        <Box sx={HeaderMenu.item}>
          <AnchorLink href={mailTo} sx={HeaderMenu.anchor}>
            {siteEmail}
          </AnchorLink>
        </Box>
        <Box sx={HeaderMenu.social}>
          {_rawSocialInfo.map((linkItem) => (
            <a key={Math.random().toString(36).substring(7)} to={linkItem.pageLink} sx={HeaderMenu.socialLinks}>
              {getSocialIcon(linkItem.socialPlatform, HeaderMenu.socialIcon)}
            </a>
          ))}
        </Box>
      </>
    );
  };
  const MainNavMenu = (mainNavData) => {
    return (
      <Flex sx={HeaderMenu.mainNav}>{mainNavData?.length > 0 && mainNavData.map((item) => <NavigationItems key={item._key} item={item} />)}</Flex>
    );
  };
  const renderMobileReserveButton = () => {
    return (
      <Flex
        sx={HeaderMenu.reserveBtnLink}
        onClick={() => {
          setIsMenuOpen(false);
          setRevMenuOpen(!isRevMenuOpen);
        }}
      >
        {isRevMenuOpen ? (
          <CloseIcon />
        ) : (
          <>
            Reserve
            <ForwardArrowIcon alt="fwdArrow" sx={isRevMenuOpen ? HeaderMenu.fwdArrow : HeaderMenu.rotateFwdArrow} />
          </>
        )}
      </Flex>
    );
  };

  const renderReserveButton = () => {
    return (
      <Flex
        sx={HeaderMenu.reserveBtnLink}
        onClick={() => {
          setIsMenuOpen(false);
          setRevMenuOpen(!isRevMenuOpen);
        }}
      >
        Reserve
        <ForwardArrowIcon alt="fwdArrow" sx={isRevMenuOpen ? HeaderMenu.fwdArrow : HeaderMenu.rotateFwdArrow} />
      </Flex>
    );
  };
  const renderDesktopNavLayout = () => (
    <>
      <Box sx={LogoStyles.base}>
        <Link to="/">
          <HeaderLogo />
        </Link>
      </Box>
      <Box sx={HeaderMenu.base}>
        {topBarLinks?.edges.length > 0 && <TopBarNavigationMenu topBarLinks={topBarLinks} />}
        <Flex sx={HeaderMenu.mainItems}>
          {mainNavigationLinks?.edges?.length > 0 && MainNavMenu(mainNavigationLinks?.edges[0].node.headerNavSections)}
          {renderReserveButton()}
        </Flex>
      </Box>
    </>
  );
  const renderMenuOpenCloseIcon = () => {
    return <Box onClick={handleMenuOpenCloseChange}>{isMenuOpen ? <CloseIcon /> : <MenuIcon />}</Box>;
  };

  const renderMobileNavMenuSection = () => {
    return (
      <Flex sx={HeaderMenu.mobileMenu}>
        {mainNavigationLinks?.edges?.length > 0 && MainNavMenu(mainNavigationLinks?.edges[0].node.headerNavSections)}
        {topBarLinks?.edges.length > 0 && <TopBarNavigationMenu topBarLinks={topBarLinks} />}
        {renderSocialAndContactSection()}
      </Flex>
    );
  };
  const renderMobileNavLayout = () => (
    <Box sx={MobileLayout.base}>
      {!isRevMenuOpen && (
        <>
          <Flex sx={MobileLayout.wrap}>
            <Flex sx={MobileLayout.logoWrap}>
              <Box sx={MobileLayout.toggelBtn}>{renderMenuOpenCloseIcon()}</Box>
              <Box sx={MobileLayout.HeaderLogo}>
                <Link to="/">
                  <HeaderLogo />
                </Link>
              </Box>
            </Flex>
            <Box>{renderMobileReserveButton()}</Box>
          </Flex>
          {isMenuOpen && <Box>{renderMobileNavMenuSection()}</Box>}
        </>
      )}
      {isRevMenuOpen && (
        <Flex sx={reserveHeader.wrap}>
          <Box sx={reserveHeader.imgWrap}>
            <Link to="/">
              <HeaderLogo />
            </Link>
          </Box>
          <Box sx={reserveHeader.headlineWrapper}>
            <ThemeHeader.H4>RESERVE</ThemeHeader.H4>
            <p>1-561-739-1700</p>
          </Box>
          <Box>{renderMobileReserveButton()}</Box>
        </Flex>
      )}
    </Box>
  );
  const getStikyClass = (isSticky) => {
    return isSticky ? HeaderStyles.stickey : {};
  };
  return (
    <header ref={headerRef} sx={{ ...HeaderStyles.base }}>
      <Flex
        id="navbar"
        sx={{
          ...HeaderStyles.inner,
          ...getStikyClass(headerSticky),
        }}
      >
        <NotifyBanner notifyComponentProp={props?.notifyComponent} />
        <Box sx={HeaderStyles.desktop}>{renderDesktopNavLayout()}</Box>
        {renderMobileNavLayout()}
        <BookNowWidgetForm isOpen={isRevMenuOpen} />
      </Flex>
    </header>
  );
}
