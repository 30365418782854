// Global layout block styles

export const container = {
  px: 3,
  mx: 'auto',
  width: '100%',
  maxWidth: 'container',
};

export const row = {
  mx: -3,
  display: 'flex',
};

export const col = {
  px: 3,
  width: '100%',
};

