import React from 'react';
import { Box } from 'theme-ui';
import CTALink from '../CTALink';
import { CloseIcon } from '../../images/icons';
import { isBrowser } from '../../assets/js/utils';

const NotifyBannerStyles = {
  base: {
    position: 'relative',
    bg: 'accent',
    fontSize: 4,
    fontWeight: 'light',
    pl: 2,
    pr: 4,
    py: 1,
    mx: (theme) => [`-${theme.space[2]}px`, null, `-${theme.space[4]}px`],
    flexGrow: 1,
    display: [null, null, 'flex'],
    justifyContent: 'center',
  },
  text: {
    p: 1,
  },
  cta: {
    p: 1,
    minWidth: [null, null, 350],
    'div a': {
      py: 0,
    },
    'div:after': {
      display: 'none',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 2,
    top: 2,
    cursor: 'pointer',
  },
};

const NotifyBanner = ({ notifyComponentProp }) => {
  const [showNotifier, setNotifier] = React.useState(true);
  React.useEffect(() => {
    if (isBrowser) {
      if (localStorage.getItem('showNotifyBanner') === JSON.stringify(false)) {
        localStorage.setItem('showNotifyBanner', false);
        setNotifier(false);
      } else {
        localStorage.setItem('showNotifyBanner', true);
      }
    }
    setNotifier(true);
  }, []);

  function closeNotifyComponent() {
    if (isBrowser) localStorage.setItem('showNotifyBanner', false);
    setNotifier(false);
  }

  return (
    <>
      {notifyComponentProp?.showNotifyBanner && showNotifier ? (
        <Box sx={NotifyBannerStyles.base}>
          <Box sx={NotifyBannerStyles.text}>{notifyComponentProp?.text}</Box>
          <Box sx={NotifyBannerStyles.cta}>
            <CTALink linkData={notifyComponentProp?.cta} />
          </Box>
          <Box sx={NotifyBannerStyles.closeIcon} onClick={closeNotifyComponent}>
            <CloseIcon />
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default NotifyBanner;
