import React from 'react';
import { Box } from 'theme-ui';
import { ListArrow } from '../../images/icons';

const ArrowList = ({ node }) => {
  const text = node.children[0].text;
  return (
    <Box sx={ArrowListStyles.base}>
      <Box sx={ArrowListStyles.icon}><ListArrow /></Box>
      <Box>{text}</Box>
    </Box>
  );
};

export default ArrowList;

const ArrowListStyles = {
  base: {
    display: 'flex',
    alignItems: 'flex-start',
		py: 2,
		":last-child": {
			mb: 4,
		},
  },
  icon: {
    mr: [2, null, 4],
    display: 'inline-block',
  },
};
