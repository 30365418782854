/** @jsx jsx */
import React from 'react';
import { jsx, Box, Label, Input, Checkbox, Select, Button, Flex } from 'theme-ui';
import { ForwardArrowIcon, ProfileIcon, RoomsIcon } from '../../images/icons';

const URI = 'https://www.hilton.com/en/book/reservation/deeplink';
const DEFAULT_ARRIVAL_DATE = '2021-09-01T12:00:00.000Z';
function openHotelUriWithParams({ arrivalDate, departureDate, numRooms, numGuests, flexibleDates }) {
  window.open(
    `${URI}?ctyhocn=PBITRQQ&arrivalDate=${arrivalDate}&departureDate=${departureDate}&numRooms=${numRooms}&numAdults=${numGuests}&flexibleDates=${flexibleDates}`,
    'Hilton Room Book'
  );
}

const BookNowWidget = ({ isOpen }) => {
  const getByDefaultDate = () => (new Date(DEFAULT_ARRIVAL_DATE).getTime() > new Date().getTime() ? new Date(DEFAULT_ARRIVAL_DATE) : new Date());
  function getDateAfterDays(days) {
    const result = getByDefaultDate();
    if (!days) return getFormattedDate(getByDefaultDate());
    result.setDate(result.getDate() + days);
    return getFormattedDate(result);
  }
  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const [formValues, setFormValues] = React.useState({
    arrivalDate: getDateAfterDays(),
    departureDate: getDateAfterDays(1),
    numRooms: 1,
    numGuests: 1,
    flexibleDates: false,
  });
  const handleChange = (e) => {
    const values = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormValues({ ...formValues, [e.target.name]: values });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    openHotelUriWithParams(formValues);
  };
  const getDepartureMinDate = () => {
    if (formValues.arrivalDate !== '') {
      const dd = new Date(formValues.arrivalDate);
      const timezoneOffset = dd.getTimezoneOffset();
      if (timezoneOffset > 0) {
        dd.setUTCHours(dd.getUTCHours() + timezoneOffset / 60);
      }
      dd.setUTCDate(dd.getUTCDate() + 1);
      return dd.toISOString().substr(0, 10);
    }
    return '';
  };
  return (
    <>
      <Flex as="form" onSubmit={(e) => handleSubmit(e)} sx={isOpen ? { ...formStyles.base, ...formStyles.active } : { ...formStyles.base }}>
        <Flex sx={formStyles.inner}>
          <Box sx={formStyles.formItem}>
            <Label sx={formStyles.label} htmlFor="arrivalDate">
              Select Arrival Date
            </Label>
            <Box sx={formStyles.inputWrap}>
              <Input
                name="arrivalDate"
                sx={formStyles.Input}
                id="arrivalDate"
                type="date"
                onChange={(e) => {
                  setFormValues({ ...formValues, arrivalDate: e.target.value });
                }}
                min={getDateAfterDays()}
                value={formValues.arrivalDate}
                onMouseOutCapture={() => {
                  setFormValues({ ...formValues, departureDate: getDepartureMinDate() });
                }}
              />
            </Box>
          </Box>
          <Box sx={formStyles.formItem}>
            <Label sx={formStyles.label} htmlFor="departureDate">
              Select Departure Date
            </Label>
            <Box sx={formStyles.inputWrap}>
              <Input
                name="_departureDate"
                sx={formStyles.Input}
                id="__departureDate"
                type="date"
                min={getDepartureMinDate()}
                onChange={(e) => {
                  setFormValues({ ...formValues, departureDate: e.target.value });
                }}
                value={formValues.departureDate}
              />
            </Box>
          </Box>
          <Box sx={formStyles.formItem}>
            <Label sx={formStyles.label} htmlFor="numRooms">
              Number of Rooms
            </Label>
            <Box sx={formStyles.inputWrap}>
              <Select
                name="numRooms"
                sx={formStyles.formItem.Input}
                id="numRooms"
                onChange={handleChange}
                value={formValues.numRooms}
                arrow={(
                  <Flex sx={formStyles.selectIcon}>
                    <Box as="svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" mr="1">
                      <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                    <RoomsIcon />
                  </Flex>
                )}
              >
                <option value="1">1 Room</option>
                <option value="2">2 Rooms</option>
                <option value="3">3 Rooms</option>
                <option value="4">4 Rooms</option>
                <option value="5">5 Rooms</option>
              </Select>
            </Box>
          </Box>
          <Box sx={formStyles.formItem}>
            <Label sx={formStyles.label} htmlFor="numGuests">
              Number of Guests
            </Label>
            <Box sx={formStyles.inputWrap}>
              <Select
                name="numGuests"
                sx={formStyles.formItem.Input}
                id="numGuests"
                onChange={handleChange}
                value={formValues.numGuests}
                arrow={(
                  <Flex sx={formStyles.selectIcon}>
                    <Box as="svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" mr="1">
                      <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                    <ProfileIcon />
                  </Flex>
                )}
              >
                <option value="1">1 Guest</option>
                <option value="2">2 Guests</option>
                <option value="3">3 Guests</option>
                <option value="4">4 Guests</option>
                <option value="5">5 Guests</option>
              </Select>
            </Box>
          </Box>
        </Flex>
        <Flex sx={formStyles.footer}>
          <Box>
            <Label mb={3}>
              <Checkbox name="flexibleDates" onChange={handleChange} checked={formValues.flexibleDates} />
              Flexible Dates?
            </Label>
          </Box>
          <Box>
            <Button sx={btnOutline}>
              Book Your Stay &nbsp;
              <ForwardArrowIcon alt="fwdArrow" />
            </Button>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default BookNowWidget;

// form related styling will go here
const formStyles = {
  active: {
    opacity: 1,
    transform: 'translateY(0)',
    pointerEvents: 'auto',
  },
  base: {
    py: 20,
    px: 4,
    right: 0,
    top: [50, null, '100%'],
    bottom: [0, null, 'auto'],
    opacity: 0,
    width: ['100%', null, 800],
    height: ['calc(100vh - 50px)', null, 'auto'],
    overflowY: 'auto',
    pointerEvents: 'none',
    flexDirection: 'column',
    backgroundColor: 'white',
    position: ['fixed', null, 'absolute'],
    transform: 'translateY(-40px)',
    transition: '0.5s ease-in-out',
    webkitOverflowScrolling: 'auto',
  },
  inner: {
    paddingTop: 23,
    paddingBottom: 17,
    justifyContent: 'space-between',
    position: 'relative',
    flexDirection: ['column', null, 'row'],
    '::before': {
      variant: 'styles.goldLine',
      width: ['60%', null, '100%'],
      height: '1px',
      top: ['auto', null, 0],
      bottom: [-110, null, 'auto'],
      left: 0,
      right: 0,
      position: 'absolute',
    },
  },
  footer: {
    justifyContent: 'space-between',
    alignItems: ['left', null, 'center'],
    flexDirection: ['column', null, 'row'],
  },
  formItem: {
    pb: 24,
    flexGrow: 1,
    maxWidth: [null, null, '160px'],
    Input: {
      border: 'none',
      borderRadius: 0,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 14,
      outline: 0,
      fontFamily: 'body',
    },
  },
  label: {
    fontSize: 14,
  },
  inputWrap: {
    width: '100%',
    borderBottom: '1px solid',
    position: 'relative',
    select: {
      pr: 6,
      fontSize: [3, null, 2],
    },
    Input: {
      fontSize: [3, null, 2],
    },
  },
  selectIcon: {
    position: 'absolute',
    right: 2,
    alignItems: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
};
const btnOutline = {
  backgroundColor: 'transparent',
  border: '1px solid',
  borderColor: 'text',
  color: 'text',
  textTransform: 'uppercase',
  borderRadius: 0,
  fontSize: 14,
  fontWeight: 'bold',
  width: [240, '60%', 240],
  transition: '0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'text',
    color: 'background',
    cursor: 'pointer',
    svg: {
      fill: 'background',
    },
  },
};
