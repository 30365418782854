/** @jsx jsx */
import { Heading, Text, jsx } from 'theme-ui';
import { keyframes } from '@emotion/react';

export const H1 = ({ children, styles, isGradient, isShimmer }) => (
  <Heading sx={{ variant: 'styles.h1', ...styles }} as="h1">
    <Text dangerouslySetInnerHTML={{__html: children}} sx={getShimmerStyle(isGradient, isShimmer)}></Text>
  </Heading>
);

export const H2 = ({ children, styles, isGradient, isShimmer }) => (
  <Heading sx={{ variant: 'styles.h2', ...styles }} as="h2">
    <Text dangerouslySetInnerHTML={{__html: children}} sx={getShimmerStyle(isGradient, isShimmer)}></Text>
  </Heading>
);

export const H3 = ({ children, styles, isGradient, isShimmer }) => (
  <Heading sx={{ variant: 'styles.h3', ...styles }} as="h3">
    <Text sx={getShimmerStyle(isGradient, isShimmer)}>{children}</Text>
  </Heading>
);

export const H4 = ({ children, styles, isGradient, isShimmer }) => (
  <Heading sx={{ variant: 'styles.h4', ...styles }} as="h4">
    <Text sx={getShimmerStyle(isGradient, isShimmer)}>{children}</Text>
  </Heading>
);

const shimmer = keyframes`
  0% {
    background-position: -450% 100%;
    background-size: 75% 100%;
  }
  100% {
    background-position: 600% 100%;
    background-size: 75% 100%;
  }
`;

const getShimmerStyle = (isGradient, isShimmer) => ({
  display: 'block',
  variant: isGradient && 'styles.textGradient',
  background: isGradient && 'linear-gradient(135deg, #F9E19D -15.34%, #C69960 20.88%, #7B5935 94.72%)',
  backgroundColor: isShimmer && '#7B5935',
  backgroundPosition: isShimmer && '0 0',
  backgroundSize: isShimmer && '100% 100%',
  backgroundRepeat: isShimmer && 'no-repeat',
  animation: isShimmer && `${shimmer} 6s ease infinite`,
});
