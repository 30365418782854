/** @jsx jsx */
import React from 'react';
import { jsx, Box, Flex } from 'theme-ui';
import { ImageFullWidth, ThemeHeader } from '../../components';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import TextBlock from '../TextBlock';
import CTALink from '../CTALink';

const MiniCard = ({ heading, _rawCardCtaLinksList, _rawCardImage, _rawText }) => {
  const [CardImageRef, isCardImageVisible] = useElementOnScreen();
  const [CardHeadingRef, isCardHeadingVisible] = useElementOnScreen();
  const [CardTextRef, isCardTextVisible] = useElementOnScreen();
  const [CardCTARef, isCardCTAVisible] = useElementOnScreen();

  return (
    <Flex sx={card.base}>
      <Box ref={CardImageRef} sx={{ transition: 'opacity 1s ease', opacity: isCardImageVisible ? 1 : 0 }}>
        <ImageFullWidth src={_rawCardImage?.asset._ref} alt="" />
      </Box>

      {heading && _rawText && (
        <Box sx={card.content}>
          <Box ref={CardHeadingRef} sx={{ transition: 'opacity 1s ease', opacity: isCardHeadingVisible ? 1 : 0 }}>
            <ThemeHeader.H2>{heading}</ThemeHeader.H2>
          </Box>
          <Box ref={CardTextRef} sx={{ transition: 'opacity 1s ease 0.25s', opacity: isCardTextVisible ? 1 : 0 }}>
            <TextBlock description={_rawText} />
          </Box>
          {_rawCardCtaLinksList && (
            <Box ref={CardCTARef} sx={{ ...card.ctaWrap, ...{ transition: 'opacity 1s ease 0.5s', opacity: isCardCTAVisible ? 1 : 0 } }}>
              {_rawCardCtaLinksList &&
                _rawCardCtaLinksList.map((cta) => {
                  return cta.link.showLink ? (
                    <Box key={Math.random().toString(36).substring(7)} sx={card.cta}>
                      <CTALink linkData={cta} />
                    </Box>
                  ) : (
                    <></>
                  );
                })}
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default MiniCard;

const card = {
  base: {
    flexDirection: 'column',
  },
  content: {
    px: [4, 6, 8],
    pt: [4, 6, 7],
    pb: [4, 5, 6],
  },
  ctaWrap: {
    variant: 'styles.ctaList',
  },
};
