import React from 'react';
import { Box } from 'theme-ui';
import TextBlock from '../TextBlock';
import { ThemeHeader, TheRayLetterLogo } from '../../components';
import CTALink from '../CTALink';
import useElementOnScreen from '../../hooks/useElementOnScreen';

const TextStyles = {
  base: (theme) => ({
    variant: 'text.p',
    color: theme === 'light' ? 'textInverted' : 'text',
  }),
  icon: {
    mb: 5,
    maxHeight: [42, null, 64],
    display: 'inline-block',
  },
  text: {
    mb: 4,
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
};
const TextComponent = ( propsData ) => {
  let isFullWidth = false;
  if (propsData.hasOwnProperty('propsData')) propsData = propsData.propsData;
  else if (propsData.hasOwnProperty('pageBody')) {
    isFullWidth = true;
    propsData = propsData.pageBody;
  }

  if (propsData.hasOwnProperty('_rawText') && !propsData.hasOwnProperty('text')) {
    propsData.text = propsData._rawText;
  } // propsData._rawText[0].children[0].text;

  const { text, ctaLinks, heading, showTheRayLogo, textTheme, textComponentLayout, theRayLogoStyle } = propsData || {};
  const getLayoutStyle = (layout) => {
    switch (layout) {
      case 'text-center-style':
        return TextStyles.textCenter;
      case 'text-left-style':
        return TextStyles.textLeft;
      case 'text-right-style':
        return TextStyles.textRight;
      default:
        return TextStyles.textCenter;
    }
  };
  const [bullets, bulletsIndex, notBullets, notBulletsIndex] = [{}, 0, {}, 0];

  // Animation references, Animate when element visible on screen
  const [TextLogoRef, isTextLogoVisible] = useElementOnScreen();
  const [TextHeadlineRef, isTextHeadlineVisible] = useElementOnScreen();
  const [TextContentRef, isTextContentVisible] = useElementOnScreen();
  const [TextCTARef, isTextCTAVisible] = useElementOnScreen();

  if (isFullWidth) {
    return (
        <Box id={"fullwidthtextarea"} sx={{ ...TextStyles.base(textTheme), ...getLayoutStyle(textComponentLayout), ...{maxWidth:'1000px', paddingLeft:'10px', paddingRight:'10px', paddingTop: '40px', margin:'30px auto'} }}>
          {showTheRayLogo && (
              <Box ref={TextLogoRef} sx={{ ...TextStyles.icon, ...{ transition: 'opacity 1s ease', opacity: isTextLogoVisible ? 1 : 0 } }}>
                <TheRayLetterLogo logoStyle={theRayLogoStyle} />
              </Box>
          )}
          {heading && (
              <Box ref={TextHeadlineRef} sx={{ transition: 'opacity 1s ease 0.25s', opacity: isTextHeadlineVisible ? 1 : 0 }}>
                <ThemeHeader.H2 styles={TextStyles.header} isGradient={textTheme == 'light'}>
                  {heading}
                </ThemeHeader.H2>
              </Box>
          )}
          {text && (
              <Box ref={TextContentRef} sx={{ transition: 'opacity 1s ease 0.5s', opacity: isTextContentVisible ? 1 : 0 }}>
                <TextBlock description={text} />
              </Box>
          )}
          {ctaLinks && (
              <Box ref={TextCTARef} sx={{ transition: 'opacity 1s ease 0.75s', opacity: isTextCTAVisible ? 1 : 0 }}>
                {ctaLinks.map((link) => link?.link?.showLink && <CTALink key={link._key} linkData={link} extraPadding={30} />)}
              </Box>
          )}
        </Box>
    );
  } else {
    return (
        <Box sx={{ ...TextStyles.base(textTheme), ...getLayoutStyle(textComponentLayout) }}>
          {showTheRayLogo && (
              <Box ref={TextLogoRef} sx={{ ...TextStyles.icon, ...{ transition: 'opacity 1s ease', opacity: isTextLogoVisible ? 1 : 0 } }}>
                <TheRayLetterLogo logoStyle={theRayLogoStyle} />
              </Box>
          )}
          {heading && (
              <Box ref={TextHeadlineRef} sx={{ transition: 'opacity 1s ease 0.25s', opacity: isTextHeadlineVisible ? 1 : 0 }}>
                <ThemeHeader.H2 styles={TextStyles.header} isGradient={textTheme == 'light'}>
                  {heading}
                </ThemeHeader.H2>
              </Box>
          )}
          {text && (
              <Box ref={TextContentRef} sx={{ transition: 'opacity 1s ease 0.5s', opacity: isTextContentVisible ? 1 : 0 }}>
                <TextBlock description={text} />
              </Box>
          )}
          {ctaLinks && (
              <Box ref={TextCTARef} sx={{ transition: 'opacity 1s ease 0.75s', opacity: isTextCTAVisible ? 1 : 0 }}>
                {ctaLinks.map((link) => link?.link?.showLink && <CTALink key={link._key} linkData={link} />)}
              </Box>
          )}
        </Box>
    );
  }

};

export default TextComponent;
