import React from 'react';
import { Box } from 'theme-ui';
import { TheRayLetterLogo } from '../../images/icons';

const TheRayLetterHorizontalLinesLogo = ({ isVisible }) => {
  return (
    <Box sx={LetterLogoStyles.base(isVisible)}>
      <TheRayLetterLogo />
    </Box>
  );
};

export default TheRayLetterHorizontalLinesLogo;

const LetterLogoStyles = {
  base: (isVisible) => ({
    display: 'flex',
    variant: 'styles.flexCenter',
    'svg': {
      transition: 'opacity 0.5s',
      opacity: isVisible ? 1 : 0,
      mx: [4, null, 7],
      width: [10, null, 18]
    },
    '&:after, &:before': {
      variant: 'styles.goldLine',
      transition: 'width 0.5s ease 0.5s',
      width: isVisible ? 125 : 0,
      height: 1.5,
      bg: 'primary',
    },
  }),
};
