/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import clientConfig from '../../../client-config';

export const Image = ({ image, src, alt, style, width, height, layout }) => {
  return (
    <GatsbyImage
      loading="lazy"
      sx={{ ...style }}
      image={image || getGatsbyImageData(src, { layout, width, height, placeholder: 'blurred' }, clientConfig.sanity)}
      alt={alt || ''}
    />
  );
};

export const ImageFullWidth = (props) => {
  return Image({
    style: { width: '100%', display: 'block', ...props.style },
    layout: 'fullWidth',
    ...props,
  });
};

export const ImageFixed = (props) =>
  Image({
    layout: 'fixed',
    ...props,
  });
