/** @jsx jsx */
import { useState } from 'react';
import { Link } from 'gatsby';
import { jsx, Box, Link as AnchorLink } from 'theme-ui';
import { isOpenInNewTab } from '../../../utils/helper';

const SubNavMenuStyles = {
  base: {
    backgroundColor: ['mobileMenu', 'background'],
    textAlign: ['left', 'center'],
    position: [null, null, 'absolute'],
    transform: [null, null, 'translate(50%)'],
    top: 50,
    right: '50%',
    minWidth: 200,
    fontWeight: 'bold',
    transition: '0.3s ease-in-out',
  },
  itemLink: {
    px: 3,
    py: 1,
    display: 'block',
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 0,
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: 6,
    ':hover': {
      backgroundColor: 'accent',
    },
  },
};

const NavigationSubMenuItems = ({ subLinks }) => {
  return (
    <div sx={{ ...SubNavMenuStyles.base }}>
      {subLinks.map((item) => (
        <Box key={item._key} sx={SubNavMenuStyles.itemLink}>
          {isOpenInNewTab(item.subUrls?.linkUrl) ? (
            <AnchorLink key={item._key} href={item.subUrls?.linkUrl || '/'} sx={SubNavMenuStyles.itemLink} target="_blank">
              {item.text}
            </AnchorLink>
          ) : (
            <Link key={item._key} to={item.subUrls?.linkUrl || '/'} sx={SubNavMenuStyles.itemLink}>
              {item.text}
            </Link>
          )}
        </Box>
      ))}
    </div>
  );
};

export default NavigationSubMenuItems;
