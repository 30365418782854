// Theme base styles
export const root = {
  variant: 'text.body',
};

export const h1 = {
  fontSize: [42, null, 9],
  letterSpacing: 2,
};

export const h2 = {
  fontSize: [6, null, 7],
};

export const h3 = {
  fontSize: [5, null, 6],
};

export const h4 = {
  fontSize: 1,
  fontFamily: 'body',
};

export const p = {
  variant: 'text.p',
};

export const pSmall = {
  fontSize: 12,
  lineHeight: 2,
  letterSpacing: 5,
  textTransform: 'uppercase',
  mb: 2,
};

export const a = {
  color: 'inherit',
};

export const img = {
  maxWidth: '100%',
  display: 'block',
};

export const code = {
  fontFamily: 'monospace',
  fontSize: 'inherit',
};

export const table = {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
};

export const th = {
  textAlign: 'left',
  borderBottomStyle: 'solid',
};

export const td = {
  textAlign: 'left',
  borderBottomStyle: 'solid',
};

export const ul = {
  pl: 0,
};

export const container = {
  variant: 'layout.container',
};

export const row = {
  variant: 'layout.row',
};

export const col = {
  variant: 'layout.col',
};

export const overlay = {
  position: 'fixed',
  display: 'grid',
  placeItems: 'center center',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  bg: 'backgroundOverlay',
  zIndex: 'overlay',
  '> *': {
    userSelect: 'text',
    pointerEvents: 'auto',
  },
};

export const flexCenter = {
  alignItems: 'center',
  justifyContent: 'center',
};

export const goldBorder = {
  borderImage: 'linear-gradient(96.68deg, #F9E19D -15.34%, #C69960 20.88%, #7B5935 94.72%)',
  borderImageSlice: 1,
};

export const goldLine = {
  content: '""',
  backgroundImage: 'linear-gradient(96.68deg, #F9E19D -15.34%, #C69960 20.88%, #7B5935 94.72%)',
};

export const textGradient = {
  background: 'linear-gradient(96.68deg, #F9E19D -15.34%, #C69960 20.88%, #7B5935 94.72%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  '> *': {
    // IOS Safari Bug fix
    WebkitBackgroundClip: 'inherit',
  },
};

export const btnReset = {
  background: 'transparent',
  border: 0,
  cursor: 'pointer',
};

export const ctaList = {
  display: 'inline-grid',
  rowGap: 2,
  columnGap: [null, null, 6],
  gridTemplateColumns: ['1', null, 'auto auto'],
};

export const wrapperSpace = {
  px: (theme) => [theme.stylesConfig.containerSpaceMobile, null, theme.stylesConfig.containerSpaceDesktop],
};

export const breakWord = {
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
};
