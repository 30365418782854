import React from 'react';
import { Box } from 'theme-ui';

const HTMLBlock = (htmlComponentProp) => {
    return (
        <>
            <Box dangerouslySetInnerHTML={{__html: htmlComponentProp?.htmlContent }}>

            </Box>
        </>
    );
};

export default HTMLBlock;