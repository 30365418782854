const getRelevantImageProp = (obj, isMobileView) => {
  const img = {};
  if (isMobileView && obj.mobileImage && obj.mobileImage.file) {
    img.url = obj.mobileImage.file.url;
  } else if (obj.image && obj.image.file) {
    img.url = obj.image.file.url;
  }
  img.title = obj.eyebrow;
  return img;
};
const isExternalUrl = (url) => {
  return url && url.indexOf('http') > -1;
};

const CardListTypes = {
  'Card Style 1': 'card-style-1',
  'Card Style 2': 'card-style-2',
  'Card Style 3': 'card-style-3',
};
const CardWrapperCSS = {
  'Card Style 1': 'row row-cols-1 row-cols-md-2 row-cols-lg-4',
  'Card Style 2': 'row row-cols-1 row-cols-md-2 row-cols-lg-3',
  'Card Style 3': 'row row-cols-1 row-cols-md-2 row-cols-lg-4',
};
const BiLateralStyleListTypes = {
  'BiLateral Style 1': 'bilateral-style-1',
  'BiLateral Style 2': 'bilateral-style-2',
};

const CTAStyleTypes = {
  Default: 'default',
  Primary: 'button',
  Secondary: 'button-link',
};

const CTAThemeTypes = {
  'Style 1': 'Style 1',
  'Style 2': 'Style 2',
};

const AssetTypes = {
  Image: 'image',
  Video: 'video',
};

const getPagePathBySlug = (slug, allSitePages) => {
  let pagePath = '';
  for (let i = 0; i < allSitePages.length; i++) {
    if (allSitePages[i].context && allSitePages[i].context.slug === slug) {
      pagePath = allSitePages[i].path;
      break;
    }
  }
  return pagePath;
};

const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const parseYoutubeURL = (url) => {
  const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/);
  return match && match[7].length == 11 ? match[7] : false;
};
const isYoutubeVideo = (url) => {
  if (url == null) return null;

  return parseYoutubeURL(url);
};
const getParameterByName = (name, url) => {
  if (typeof window === 'undefined') return url;
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const appendZeroOnSlideCount = (count) => {
  return count < 10 ? `0${count}` : count;
};

const mailTo = 'mailto:THERAY.RESERVATIONS@HILTON.COM';

const mapLocationURL = 'https://goo.gl/maps/MdrQSPa5ZNTKhgk57';

const isBrowser = typeof window !== `undefined`;

/*
 ** We have passed harcode URL string just for making build process successfull
 ** becuase window object will be undefined during build process
 ** but it'll be available to browser after deployement
 */
const currentURL = isBrowser ? window.document.location.href : 'https://therayhotel.com/';

/*
 ** DigitalData variable is used for adobedtm link
 */
const digitalData = {
  page: {
    attributes: { siteExperience: 'R', contentTypes: 'images,text' },
    category: { brand: 'QQ', primaryCategory: 'homepage', siteName: 'therayhotel', siteType: 'L', subSection: '', subSubSection: '' },
    pageInfo: {
      destinationURL: isBrowser ? window.document.location.href : currentURL,
      language: 'en_US',
      pageName: 'therayhotel:homepage',
      pageTitle: isBrowser ? window.document.title : 'The Ray Hotel',
    },
  },
  product: [{ productInfo: { productID: 'PBITRQQ' } }],
};

export {
  getRelevantImageProp,
  isExternalUrl,
  CardListTypes,
  BiLateralStyleListTypes,
  getPagePathBySlug,
  CTAStyleTypes,
  AssetTypes,
  isYoutubeVideo,
  getYoutubeId,
  CardWrapperCSS,
  getParameterByName,
  CTAThemeTypes,
  appendZeroOnSlideCount,
  mailTo,
  currentURL,
  isBrowser,
  digitalData,
  mapLocationURL,
};
