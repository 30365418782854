/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx, Flex, Link as AnchorLink } from 'theme-ui';
import { isOpenInNewTab } from '../../../utils/helper';

const TopBarNavMenuStyles = {
  base: {
    justifyContent: ['flex-start', null, 'flex-end'],
    flexShrink: 0,
  },
  item: {
    ml: [12, 24],
    pt: [24, null, 0],
    pb: [38, null, 0],
    '&:nth-of-type(1)': {
      ml: 0,
    },
  },
  itemLink: {
    color: 'primary',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 0,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    letterSpacing: 6,
  },
};

const TopBarNavigationMenu = ({ topBarLinks }) => {
  return (
    <Flex sx={TopBarNavMenuStyles.base}>
      {topBarLinks &&
        topBarLinks.edges[0].node.topHeaderNav.map((item) => (
          <div key={item._key} sx={TopBarNavMenuStyles.item}>
            {isOpenInNewTab(item.link) ? (
              <AnchorLink key={item._key} href={item.link || '/'} sx={TopBarNavMenuStyles.itemLink} target="_blank">
                {item.title}
              </AnchorLink>
            ) : (
              <Link key={item._key} to={item.link || '/'} sx={TopBarNavMenuStyles.itemLink}>
                {item.title}
              </Link>
            )}
          </div>
        ))}
    </Flex>
  );
};

export default TopBarNavigationMenu;
