/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import React from 'react';
import { Header, Footer } from '../../blocks';
import ThemeProvider from '../ThemeProvider';
import { AppControlProvider } from '../../context/appControl.context';

const layout = (props) => {
  const { children, headerData, topBarLinks, siteSettings, isHeroCompInclude, notifyComponent } = props;
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child);
  });
  return (
    <AppControlProvider>
      <ThemeProvider>
        <Flex sx={{ flexDirection: 'column', minHeight: '100vh' }}>
          <Header
            mainNavigationLinks={headerData}
            topBarLinks={topBarLinks}
            siteSettings={siteSettings}
            isHeroCompInclude={isHeroCompInclude}
            notifyComponent={notifyComponent}
          />
          <div sx={{ flexGrow: 1 }}>{childrenWithProps}</div>
          <Footer siteSettings={siteSettings} />
        </Flex>
      </ThemeProvider>
    </AppControlProvider>
  );
};

export default layout;
