import React from 'react';
import { Grid, Box } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import { serializers } from '../../utils/blockMarks';

const AdjacentBlock = ({ leftText, rightText }) => {
  return (
    <Grid gap={[null, null, "8px"]} columns={[1, null, 2]} sx={AdjacentStyles.base}>
      <Box sx={AdjacentStyles.item}>
        <BlockContent blocks={leftText} serializers={serializers} />
      </Box>
      <Box sx={AdjacentStyles.item}>
        <BlockContent blocks={rightText} serializers={serializers} />
      </Box>
    </Grid>
  );
};

export default AdjacentBlock;

const AdjacentStyles = {
  base: {
    textAlign: 'left',
  },
  item: {
    flexGrow: 1,
    'ul': {
      mb: 0,
    }
  },
};
