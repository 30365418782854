import React from 'react';
import { jsx, Box, Grid } from 'theme-ui';
import MiniCard from '../MiniCard';

const CardsView = (props) => {
  const { cardList: List } = props?.pageBody;
  return (
    <Grid className="grid" gap="8px" columns={[1, null, 2]} sx={cardList}>
      {List.map((element) => {
        return (
          <Box key={Math.random().toString(36).substring(7)}>
            <MiniCard {...element} />
          </Box>
        );
      })}
    </Grid>
  );
};

export default CardsView;

const cardList = {
  pb: 6,
  pt: 10,
};
