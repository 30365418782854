import React, { Component } from 'react';
import loadable from '@loadable/component';
import ThemeProvider from '../ThemeProvider';

class BaseComponent extends Component {
  getModuleName = (moduleName) => {
    let compName = null;
    if (/selectHero/.test(moduleName)) {
      compName = 'Hero';
    }
    //Will Remove once all bilateral blocks query changed
    if (/selectIntro/.test(moduleName)) {
      compName = 'Intro';
    }
    if (/selectBilateral/.test(moduleName)) {
      compName = 'BilateralBlock';
    }
    if (/cardWithCTALinksBlock/.test(moduleName)) {
      compName = 'CardWithCTALinks';
    }
    if (/bilateralCarouselComponent/.test(moduleName)) {
      compName = 'BilateralImageCarousel';
    }
    if (/twoCardView/.test(moduleName)) {
      compName = 'CardsView';
    }
    if (/selectBanner/.test(moduleName)) {
      compName = 'Banner';
    }
    if (/contactBlock/.test(moduleName)) {
      compName = 'ContactBlock';
    }
    if (/sideNavSlider/.test(moduleName)) {
      compName = 'SideNavSections';
    }
    if (/selectGalleryImages/.test(moduleName)) {
      compName = 'Gallery';
    }
    if (/selectTwoColumnTextComp/.test(moduleName)) {
      compName = 'TwoColumnTextComp';
    }
    if (/selectBilateralCarousel/.test(moduleName)) {
      compName = 'BilateralImageCarousel';
    }
    if (/selectCardWithCTALinks/.test(moduleName)) {
      compName = 'CardWithCTALinks';
    }
    if (/selectText/.test(moduleName)) {
      compName = 'TextComponent';
    }
    if (/textComponent/.test(moduleName)) {
      compName = 'TextComponent';
    }
    if (/bilateralBlock/.test(moduleName)) {
      compName = 'BilateralBlock';
    }
    if (/notifyBanner/.test(moduleName)) {
      compName = 'NotifyBanner';
    }
    if (/htmlBlock/.test(moduleName)) {
      compName = 'HTMLBlock';
    }
    if (/selectAccordions/.test(moduleName)) {
      compName = 'AccordionComponent';
    }
    if (/selectPrefooterCTA/.test(moduleName)) {
      compName = 'PrefooterCTA';
    }
    return compName;
  };
  constructor() {
    super();
    this._loadedComponent = null;
    this._sectionHeaders = null; //prepare section headers to be used in banner component
  }

  loadComponents = () => {
    const { pageComponents, pageContext, pageBody } = this.props;
    if (pageComponents) {
      try {
        const totalComponents = pageComponents.length;
        this._loadedComponent = pageComponents.map((config, index) => {
          const body = pageBody[index];
          let moduleName = this.getModuleName(config._type);
          if (moduleName) {

            if (moduleName == 'TextComponent') {
              config = {config};
            }

            try {
              config.moduleName = moduleName;
              const ModuleComponent = loadable(() => import(`../../blocks/${moduleName}`));
              return (
                <ModuleComponent
                  {...config}
                  key={Math.random().toString(36).substring(7)}
                  pageBody={body}
                  DEVICE_VIEW={this.props?.DEVICE_VIEW}
                  headerState={this.props?.headerState}
                />
              );
            } catch (e) {
              return null;
            }
          }
          return null;
        });
      } catch (e) {
        throw e;
      }
    }
    return (
      <ThemeProvider {...this.props}>
        <div className={''}> {this._loadedComponent}</div>
      </ThemeProvider>
    );
  };

  render() {
    return <div className="main-wrapper">{this.loadComponents()}</div>;
  }
}
export default BaseComponent;
