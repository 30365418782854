/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { BaseComponent } from '../components';
import Layout from '../components/Layout/layout';

import { currentURL, digitalData } from '../assets/js/utils';
import favicon from '../images/favicon.png';

export const query = graphql`
  query ($slug: String) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      metaTitle
      metaImage {
        asset {
          url
        }
      }
      metaDescription
      slug {
        current
      }
      _rawComponents
      components {
        ... on SanityNavSlideContainer {
          title
          sideNavSliderItems {
            title
            bgColor
            _type
            carousel {
              title
              bgColor
              carouselStyle
              _type
              _rawBilateralCarousel
              _rawBilateralTextBlock
            }
          }
        }

        ... on SanityBilateralCarouselComponent {
          id
          title
          bgColor
          carouselStyle
          _rawBilateralCarousel
          _rawBilateralTextBlock
        }

        ... on SanityTwoCardView {
          cardList {
            _rawCardCtaLinksList
            _rawCardImage
            _rawText
            bgColor
            heading
            title
          }
          title
          _type
          _key
        }

        ... on SanityIntroComponent {
          _rawCarouselImages
          _rawCta
          _rawText
          bgColor
          _type
          styleType
          title
        }

        ... on SanityBanner {
          title
          _rawText
          _rawImage
          _rawCta
          bgColor
          styleType
          _rawBroucherPdf(resolveReferences: { maxDepth: 5 })
        }

        ... on SanityHtmlBlock {
          htmlContent
          _type
        }
        
        ... on SanityTextComponent {
          textComponentLayout
          showTheRayLogo
          theRayLogoStyle
          heading
          _rawText
          textTheme
          ctaLinks {
              _key
              label
              showIcon
              link {
                linkUrl
                showLink
              }
            }
          _type
        }
        
        ... on SanityNotifyBanner {
          title
          text
          showNotifyBanner
          cta {
            _type
            label
            link {
              _key
              linkUrl
              showLink
            }
            showIcon
          }
          _type
        }
        
        ... on SanityHeroComponent {
          title
          _rawCarouselDayImages
          _rawCarouselNightImages
          showTheRayLogoVectorIcon
          showTheRayLogoHorizontalLines
          chooseVerticalLinesPlacement
          _type
          _rawCta
          _rawText
          bgColor
          _key
          style
          mode
        }

        ... on SanityBilateralBlock {
          _rawBilateralImage
          _rawBilateralTextBlock
          bgColor
          _type
          biLateralBlockStyle
          biLateralLayoutStyle
          biLateralMobileLayoutStyle
          showCenterVerticalLine
          title
          _key
        }
        ... on SanityTwoColumnTextComp {
          _rawTwoColTextLeftCol
          _rawTwoColTextRightCol
          bgColor
          textTheme
          id
          title
          _id
          _key
        }
        ... on SanityGalleryImages {
          imagesList {
            _key
            _type
            _rawAsset
            _rawHotspot
            _rawCrop
          }
        }

        ... on SanityAccordionArr {
          title
          accordionArr {
            _key
            title
            _rawText
            _type
            ctaLinks {
              _key
              label
              showIcon
              link {
                linkUrl
                showLink
              }
            }
          }
        }

        ... on SanityPrefooterCTA {
          title
          cta {
            label
            link {
              linkUrl
              showLink
            }
            showIcon
          }
        }

        ... on SanityCardWithCTALinksBlock {
          title
          heading
          cardWithCTALinksBlockStyle
          cardCTALinksList {
            _key
            _type
            title
            linkTo
            _rawLinkImage
            _rawNavItems
          }
          bgColor
          _type
          _key
          _id
        }
      }
    }

    siteSettings: sanitySiteSettings {
      id
      _rawSiteContactInformation
      _rawSocialInfo
      description
      title
      _type
    }

    headerLinks: allSanityHeaderNav2 {
      edges {
        node {
          id
          title
          headerNavSections {
            _key
            title
            class
            kind
            navItemUrl
            navItems {
              _key
              _type
              text
              subUrls {
                linkUrl
                showLink
              }
            }
          }
        }
      }
      totalCount
    }

    topBarLinks: allSanityTopHeaderNavContainer {
      edges {
        node {
          title
          topHeaderNav {
            _key
            buttonType
            link
            title
          }
        }
      }
    }
  }
`;

const pageTemplate = ({ data: { headerLinks, topBarLinks, page, siteSettings }, pageContext }) => {
  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.digitalData = digitalData || {};
    }
  }, []);
  return (
    <Layout
      headerData={headerLinks}
      notifyComponent={page?.components.filter((c) => c?._type === 'notifyBanner')[0]}
      topBarLinks={topBarLinks}
      siteSettings={siteSettings}
      isHeroCompInclude={page?.components.filter((c) => c?._type === 'heroComponent').length > 0}
    >
      <Helmet>
        <meta name="description" content={page.metaDescription} charSet="utf-8" />
        <meta property="og:image" content={page.metaImage?.asset.url}/>
        <meta name="twitter:image" content={page.metaImage?.asset.url}/>
        <title>{`${page.metaTitle} | Ray Hotel`}</title>
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <link rel="canonical" href={currentURL} />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      {page && <BaseComponent pageBody={[...page?.components]} pageContext={pageContext} pageComponents={[...page?._rawComponents]} />}
    </Layout>
  );
};
/*         */

export default pageTemplate;
