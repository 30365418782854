import React from 'react';
import { Box } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import { serializers } from '../../utils/blockMarks';
import ArrowList from '../ArrowList';

const TextStyle = {
  base: {
    pb: 4,
    variant: 'text.p',
  },
  text: {
    mb: 4,
  },
};
const TextBlock = ({ description, styleBlock }) => {
  return (
    <Box sx={{ ...TextStyle.base, ...styleBlock }}>
      {description && (
        <Box>
          {description.map((item, index, arr) => {
            if (item.listItem === 'arrow') {
              return <ArrowList key={Math.random().toString(36).substring(7)} node={item} />;
            }
            return (
              <Box key={Math.random().toString(36).substring(7)} sx={TextStyle.text}>
                <BlockContent blocks={item} serializers={serializers} />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default TextBlock;
