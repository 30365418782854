const getImagePathByImageName = (imageName) => {
  const extension = `${imageName}`.split('-').pop();
  return `${imageName}`.replace('image-', 'https://cdn.sanity.io/images/r6duunlc/production/').replace(`-${extension}`, `.${extension}`);
};

function isURL(str) {
  const regExp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regExp.test(str);
}
const isOpenInNewTab = (_link) => {
  const _url = isURL(_link || '') && new URL(_link);
  return typeof window !== 'undefined' && isURL(_link) && window.location.hostname !== _url.hostname;
};
export { getImagePathByImageName, isOpenInNewTab };
