/** @jsx jsx */
import { useState } from 'react';
import { jsx, Text, Box, Link as AnchorLink } from 'theme-ui';
import { Link } from 'gatsby';
import { ForwardArrowIcon } from '../../images/icons';
import { isOpenInNewTab } from '../../utils/helper';

const CTALink = (props) => {
  const {
    linkData: { label, link, showIcon, arrowIcon },
    showUnderline,
    reverseIcon,
      extraPadding
  } = props || {};

  const [ctaLabelHover, setctaLabelHover] = useState(false);
  const renderCTAIcon = (src, alt) => <ForwardArrowIcon alt={alt} />;

  let linkurl = link?.linkUrl || '/';
  if (linkurl.indexOf('mailto:') == -1) {
    if (extraPadding) {
    return (
        <Box sx={{marginLeft: '30px'}}>
          <Box sx={CtaStyle.base(showIcon, reverseIcon)}>
            {isOpenInNewTab(link?.linkUrl) ? (
                <AnchorLink
                    onMouseOver={() => setctaLabelHover(true)}
                    onMouseLeave={() => setctaLabelHover(false)}
                    sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline)}}
                    href={link?.linkUrl}
                    target="_blank"
                >
                  <Text>{label}</Text>
                  {showIcon && link?.linkUrl !== undefined && (
                      <Box
                          sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                  )}
                </AnchorLink>
            ) : (
                <Link
                    onMouseOver={() => setctaLabelHover(true)}
                    onMouseLeave={() => setctaLabelHover(false)}
                    sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline), ...(link?.linkUrl === undefined && CtaStyle.linkDisabled)}}
                    to={link?.linkUrl || '/'}
                >
                  <Text>{label}</Text>
                  {showIcon && link?.linkUrl !== undefined && (
                      <Box
                          sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                  )}
                </Link>
            )}
          </Box>
        </Box>
    );
  } else {
      console.log("not mailto, not extra padding");

      return (
        <Box sx={CtaStyle.base(showIcon, reverseIcon)}>
          {isOpenInNewTab(link?.linkUrl) ? (
              <AnchorLink
                  onMouseOver={() => setctaLabelHover(true)}
                  onMouseLeave={() => setctaLabelHover(false)}
                  sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline)}}
                  href={link?.linkUrl}
                  target="_blank"
              >
                <Text>{label}</Text>
                {showIcon && link?.linkUrl !== undefined && (
                    <Box sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                )}
              </AnchorLink>
          ) : (
              <Link
                  onMouseOver={() => setctaLabelHover(true)}
                  onMouseLeave={() => setctaLabelHover(false)}
                  sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline), ...(link?.linkUrl === undefined && CtaStyle.linkDisabled)}}
                  to={link?.linkUrl || '/'}
              >
                <Text>{label}</Text>
                {showIcon && link?.linkUrl !== undefined && (
                    <Box sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                )}
              </Link>
          )}
        </Box>
    );
  }
  } else {

    if (extraPadding) {
      console.log("mailto, extra padding");

      return (
          <Box sx={{marginLeft: '30px'}}>
            <Box sx={CtaStyle.base(showIcon, reverseIcon)}>
              {isOpenInNewTab(link?.linkUrl) ? (
                  <AnchorLink
                      onMouseOver={() => setctaLabelHover(true)}
                      onMouseLeave={() => setctaLabelHover(false)}
                      sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline)}}
                      href={link?.linkUrl}
                      target="_blank"
                  >
                    <Text>{label}</Text>
                    {showIcon && link?.linkUrl !== undefined && (
                        <Box
                            sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                    )}
                  </AnchorLink>
              ) : (
                  <AnchorLink
                      onMouseOver={() => setctaLabelHover(true)}
                      onMouseLeave={() => setctaLabelHover(false)}
                      sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline), ...(link?.linkUrl === undefined && CtaStyle.linkDisabled)}}
                      href={link?.linkUrl}
                  >
                    <Text>{label}</Text>
                    {showIcon && link?.linkUrl !== undefined && (
                        <Box
                            sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                    )}
                  </AnchorLink>
              )}
            </Box>
          </Box>
      );
    } else {
      console.log("mailto, not extra padding");

      return (
          <Box sx={CtaStyle.base(showIcon, reverseIcon)}>
            {isOpenInNewTab(link?.linkUrl) ? (
                <AnchorLink
                    onMouseOver={() => setctaLabelHover(true)}
                    onMouseLeave={() => setctaLabelHover(false)}
                    sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline)}}
                    href={`mailto:${link?.linkUrl}`}
                    target="_blank"
                >
                  <Text>{label}</Text>
                  {showIcon && link?.linkUrl !== undefined && (
                      <Box sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                  )}
                </AnchorLink>
            ) : (
                <AnchorLink
                    onMouseOver={() => setctaLabelHover(true)}
                    onMouseLeave={() => setctaLabelHover(false)}
                    sx={{...CtaStyle.link, ...(showUnderline && CtaStyle.underline), ...(link?.linkUrl === undefined && CtaStyle.linkDisabled)}}
                    href={link?.linkUrl}
                >
                  <Text>{label}</Text>
                  {showIcon && link?.linkUrl !== undefined && (
                      <Box sx={CtaStyle.icon(reverseIcon)}>{renderCTAIcon(arrowIcon?.asset._ref, `cta-${label}`)}</Box>
                  )}
                </AnchorLink>
            )}
          </Box>
      );
    }
  }
};

const CtaStyle = {
  base: (isIcon, reverseIcon) => ({
    display: 'inline-block',
    direction: reverseIcon && 'rtl',
    '> a': {
      display: 'inline-flex',
      alignItems: 'center',
      transform: 'translateX(0)',
      transition: 'transform 0.5s ease-in-out',
      py: 15,
    },
    '::after': {
      content: "''",
      transition: 'width 0.5s ease-in-out',
      width: 0,
      height: '2px',
      display: ['none', null, 'block'],
      variant: 'styles.goldLine',
      backgroundImage: 'linear-gradient(88.7deg, #FFE298 -48.51%, #D5A45F 70.9%, #886238 183.08%)',
    },
    '&:hover': isIcon && {
      '> a': {
        transform: !reverseIcon ? [null, null, 'translateX(25px)'] : [null, null, 'translateX(-25px)'],
      },
      '::after': {
        width: 'calc(100% + 25px)',
      },
    },
  }),
  link: {
    display: 'block',
    fontSize: 2,
    lineHeight: 1.5,
    color: 'inherit',
    textDecoration: 'none',
    letterSpacing: 6,
    textTransform: 'uppercase',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontStyle: 'normal',
    transition: '0.3s ease-in-out',
    fill: 'currentColor',
    textAlign: 'left',
    position: 'relative',
  },
  icon: (reverseIcon) => ({
    ml: !reverseIcon && 3,
    mr: reverseIcon && 3,
    transform: reverseIcon && 'rotate(180deg)',
    display: 'inline-block',
  }),
  underline: {
    textDecoration: 'underline',
  },
  linkDisabled: {
    pointerEvents: 'none',
  },
};

export default CTALink;
