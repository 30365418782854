/** @jsx jsx */
import React from 'react';
import { jsx, Flex, Box } from 'theme-ui';
import { ImageFullWidth } from '../../components';
import TextBlockComponent from '../TextComponent';
import useElementOnScreen from '../../hooks/useElementOnScreen';

const BilateralBlockComponent = ({ pageBody }) => {
  const { _rawBilateralTextBlock, _rawBilateralImage, bgColor, showCenterVerticalLine } = pageBody;
  const [DEVICE_VIEW, setDEVICE_VIEW] = React.useState({
    IsMobile: false,
    IsTablet: false,
    IsDesktop: true,
  });
  const resize = () => {
    setDEVICE_VIEW({
      IsMobile: window.innerWidth <= 640,
      IsTablet: window.innerWidth >= 640 && window.innerWidth < 1024,
      IsDesktop: window.innerWidth >= 1024,
    });
  };
  React.useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [window.innerWidth]);

  const renderBilateralTextComponent = (data) => <TextBlockComponent propsData={data} />;
  const renderBilateralImage = (src, alt) => {
    return <ImageFullWidth src={src} alt={alt} />;
  };

  const getLayoutStyle = (style) => {
    switch (style) {
      case 'right-image-align':
        return bilateralBlockStyles.rightAlign;
      case 'left-image-align':
        return bilateralBlockStyles.leftAlign;
      default:
        return bilateralBlockStyles.rightAlign;
    }
  };

  const getMobileViewLayoutStyle = (style) => {
    switch (style) {
      case 'top':
        return bilateralBlockStyles.top;
      case 'bottom':
        return bilateralBlockStyles.bottom;
      default:
        return bilateralBlockStyles.top;
    }
  };

  const getBlockStyle = (style) => {
    switch (style) {
      case 'style-1':
        return bilateralBlockStyles.style1.text;
      case 'style-2':
        return bilateralBlockStyles.style2.text;
      case 'style-3':
        return bilateralBlockStyles.style3.text;
      case 'style-4':
        return bilateralBlockStyles.style4.text;
      default:
        return bilateralBlockStyles.style1.text;
    }
  };

  const [BilateralImageRef, isBilateralImageVisible] = useElementOnScreen();
  const [BilateralVerticalLineRef, isBilateralVerticalLineVisible] = useElementOnScreen();

  return (
    <>
      <Flex
        sx={{
          ...bilateralBlockStyles.base,
          backgroundColor: bgColor,
          ...getLayoutStyle(pageBody?.biLateralLayoutStyle),
        }}
      >
        {_rawBilateralTextBlock && (
          <Flex
            sx={{
              ...bilateralBlockStyles.text(showCenterVerticalLine),
              ...getBlockStyle(pageBody?.biLateralBlockStyle),
              ...getMobileViewLayoutStyle(DEVICE_VIEW.IsMobile ? pageBody?.biLateralMobileLayoutStyle : ''),
            }}
          >
            {renderBilateralTextComponent(_rawBilateralTextBlock)}
            {showCenterVerticalLine && (
              <Box ref={BilateralVerticalLineRef} sx={bilateralBlockStyles.verticalLine(isBilateralVerticalLineVisible)}></Box>
            )}
          </Flex>
        )}
        {_rawBilateralImage?.asset && (
          <Flex
            ref={BilateralImageRef}
            sx={{ ...bilateralBlockStyles.img, ...{ transition: 'opacity 1s ease', opacity: isBilateralImageVisible ? 1 : 0 } }}
          >
            {renderBilateralImage(_rawBilateralImage?.asset._ref, _rawBilateralImage?.asset?.alt)}
          </Flex>
        )}
      </Flex>
    </>
  );
};

const bilateralBlockStyles = {
  base: {
    flexDirection: ['column', null, 'row'],
  },
  text: (showVerticalLine) => ({
    width: ['100%', null, '40%'],
    color: 'text',
    textAlign: 'center',
    variant: 'styles.flexCenter',
    position: 'relative',
    pt: showVerticalLine ? [5, null, 10] : 5,
    pb: showVerticalLine ? [7, null, 10] : 5,
  }),
  verticalLine: (isBilateralTextVisible) => ({
    variant: 'styles.goldLine',
    width: '1px',
    bottom: 0,
    left: 0,
    right: 0,
    mx: 'auto',
    position: 'absolute',
    transition: 'height 1s ease 1s',
    height: isBilateralTextVisible ? ['64px', null, '125px'] : 0,
  }),
  img: {
    width: ['100%', null, '60%'],
  },
  rightAlign: {
    flexDirection: ['column', null, 'row'],
  },
  leftAlign: {
    flexDirection: ['column', null, 'row-reverse'],
  },
  top: {
    order: 1,
  },
  bottom: {
    order: 0,
  },
  style1: {
    text: {
      px: [3, null, 10],
      minHeight: [null, null, 800],
    },
  },
  style2: {
    text: {
      px: [3, null, 7],
      minHeight: [null, null, 800],
    },
  },
  style3: {
    text: {
      px: [3, null, 5],
      minHeight: [null, null, 800],
    },
  },
  style4: {
    text: {
      px: [3, null, 7],
      minHeight: [null, null, 544],
    },
  },
};

export default BilateralBlockComponent;
