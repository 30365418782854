import React from 'react';
import { AdjacentBlock } from '../blocks';

export const boldTitleRender = (props) => <span style={{ color: '#666' }}>{props.children}</span>;

export const SuperscriptTagRender = ({ children }) => <sup>{children}</sup>;

export const blockMarks = {
  decorators: [
    {
      title: 'Strong',
      value: 'strong',
      blockEditor: {
        render: boldTitleRender,
      },
    },
    { title: 'Emphasis', value: 'em' },
    { title: 'Code', value: 'code' },
    { title: 'Underline', value: 'underline' },
    { title: 'Strike', value: 'strike-through' },
    {
      title: 'Superscript Tag',
      value: 'sup',
      blockEditor: {
        render: SuperscriptTagRender,
      },
    },
  ],
};
export const serializers = {
  types: {
    code: ({ node }) => (
      <pre data-language={node.language}>
        <code>{node.code}</code>
      </pre>
    ),
    adjacentBlock: (props) => {
      return <AdjacentBlock leftText={props?.node.leftText} rightText={props?.node.rightText} />;
    },
    block: (props) => <>{props?.children}</>,
  },
};
