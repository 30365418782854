/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { jsx, Box, Link as AnchorLink } from 'theme-ui';

import NavigationSubMenuItems from '../NavigationSubMenuItems';
import { CaretIcon } from '../../../images/icons';

import { isOpenInNewTab } from '../../../utils/helper';

const MainNavMenuStyles = {
  desktop: {
    display: ['none', 'none', 'block'],
  },
  mobile: {
    display: ['block', 'block', 'none'],
  },
  item: {
    position: 'relative',
    px: [0, 0, 3],
    py: 1,
    pb: [24, 24, 0],
    '> div': {
      opacity: [1, null, 0],
      zIndex: -1,
      top: 70,
    },
    '&:hover': {
      '> div': {
        top: 50,
        opacity: [1, null, 1],
        zIndex: 1,
        transition: 'top 0.5s ease, opacity 0.5s ease',
      },
    },
  },
  itemLink: (isActive) => ({
    color: isActive ? 'text' : 'text',
    fontFamily: 'heading',
    fontWeight: 'light',
    fontStyle: isActive ? 'italic' : 'normal',
    fontSize: 5,
    textDecoration: isActive ? 'underline' : 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  }),
  caret: {
    ml: '8px',
    width: 12,
  },
  fwdArrow: {
    ml: '8px',
    width: 12,
    transition: '0.5s ease-in-out',
    transform: 'rotate(90deg)',
    fill: 'currentColor',
  },
  rotateFwdArrow: {
    ml: '8px',
    width: 12,
    transition: '0.5s ease-in-out',
    transform: 'rotate(0deg)',
  },
};

const NavigationItems = ({ item }) => {
  const { _key, title, navItemUrl, kind, navItems } = item;
  const [showSubMenu, setShowSubMenu] = React.useState(false);
  const renderMobileItem = () => {
    return (
      <Box
        sx={{ ...MainNavMenuStyles.mobile, ...MainNavMenuStyles.item }}
        onClick={navItems?.length > 0 ? () => setShowSubMenu(!showSubMenu) : () => setShowSubMenu(false)}
      >
        {kind !== 'button' &&
          typeof window !== 'undefined' &&
          (isOpenInNewTab(navItemUrl) ? (
            <AnchorLink key={_key} href={navItemUrl || '/'} sx={{ ...MainNavMenuStyles.itemLink(navItemUrl === window.location.pathname) }}>
              {title}
              {navItems?.length > 0 && <CaretIcon alt="caret" sx={MainNavMenuStyles.caret} />}
            </AnchorLink>
          ) : (
            <Link key={_key} to={navItemUrl || '/'} sx={{ ...MainNavMenuStyles.itemLink(navItemUrl === window.location.pathname) }}>
              {title}
              {navItems?.length > 0 && <CaretIcon alt="caret" sx={MainNavMenuStyles.caret} />}
            </Link>
          ))}
        {navItems?.length > 0 && showSubMenu && <NavigationSubMenuItems subLinks={navItems} />}
      </Box>
    );
  };
  const renderDesktopItem = () => {
    return (
      <Box sx={{ ...MainNavMenuStyles.desktop, ...MainNavMenuStyles.item }}>
        {kind !== 'button' &&
          typeof window !== 'undefined' &&
          (isOpenInNewTab(navItemUrl) ? (
            <AnchorLink
              key={_key}
              href={navItemUrl}
              target="_blank"
              sx={MainNavMenuStyles.itemLink(
                navItemUrl !== null
                  ? navItemUrl === window.location.pathname
                  : navItems.filter((link) => link?.subUrls.linkUrl === window.location.pathname).length > 0
              )}
            >
              {title}
              {navItems?.length > 0 && <CaretIcon alt="caret" sx={MainNavMenuStyles.caret} />}
            </AnchorLink>
          ) : (
            <Link
              key={_key}
              to={navItemUrl || '/'}
              sx={MainNavMenuStyles.itemLink(
                navItemUrl !== null
                  ? navItemUrl === window.location.pathname
                  : navItems.filter((link) => link?.subUrls.linkUrl === window.location.pathname).length > 0
              )}
            >
              {title}
              {navItems?.length > 0 && <CaretIcon alt="caret" sx={MainNavMenuStyles.caret} />}
            </Link>
          ))}
        {navItems?.length > 0 && <NavigationSubMenuItems subLinks={navItems} />}
      </Box>
    );
  };
  const renderLink = () =>
    isOpenInNewTab(navItemUrl) ? (
      <AnchorLink
        key={_key}
        href={navItemUrl}
        target="_blank"
        sx={MainNavMenuStyles.itemLink(
          navItemUrl !== null
            ? navItemUrl === window.location.pathname
            : navItems.filter((link) => link?.subUrls.linkUrl === window.location.pathname).length > 0
        )}
      >
        {title}
        {navItems?.length > 0 && <CaretIcon alt="caret" sx={MainNavMenuStyles.caret} />}
      </AnchorLink>
    ) : (
      <Link
        key={_key}
        to={navItemUrl || '/'}
        sx={MainNavMenuStyles.itemLink(
          navItemUrl !== null
            ? navItemUrl === window.location.pathname
            : navItems.filter((link) => link?.subUrls.linkUrl === window.location.pathname).length > 0
        )}
      >
        {title}
        {navItems?.length > 0 && <CaretIcon alt="caret" sx={MainNavMenuStyles.caret} />}
      </Link>
    );
  return (
    <>
      {renderMobileItem()}
      {renderDesktopItem()}
    </>
  );
};

export default NavigationItems;
