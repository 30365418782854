import React, { useRef, useEffect, useState } from 'react';

const useElementOnScreen = (options) => {
  const _options = {
    root: options?.root || null,
    rootMargin: options?.rootMargin || '0px',
    threshold: options?.threshold || 0.25,
    animateOnlyOnce: options?.animateOnlyOnce || true,
  };
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimate, setIsAnimate] = useState(false);
  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
    if (_options?.animateOnlyOnce) {
      if (entry.isIntersecting) setIsAnimate(true);
    }
  };
  useEffect(() => {
    window.addEventListener('load', () => {
      setIsAnimate(false);
    });
  });
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, _options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, _options]);
  return [containerRef, _options?.animateOnlyOnce ? isAnimate : isVisible];
};
export default useElementOnScreen;
