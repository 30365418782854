import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'theme-ui';

const TheRayLetterLogo = (props) => {
  const { logoStyle } = props;
  const headerLogoData = useStaticQuery(graphql`
    query TheRayLetterLogoQuery {
      file(relativePath: { eq: "the-ray-letter-logo.png" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `);

  const getLogoStyle = (style) => {
    switch (style) {
      case 'logo-style-1':
        return {};
      case 'logo-style-2':
        return {};
      default:
        return {};
    }
  };
  return (
    <Box sx={{ ...getLogoStyle(logoStyle) }}>
      <GatsbyImage image={headerLogoData.file.childImageSharp.gatsbyImageData} alt="alt" />
    </Box>
  );
};

export default TheRayLetterLogo;
