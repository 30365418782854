/** @jsx jsx */
import React from 'react';
import { jsx, Grid, Box, Link as AnchorLink } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import { ThemeHeader } from '../../components';
import { serializers } from '../../utils/blockMarks';
import { CTALink } from '..';
import { mapLocationURL } from '../../assets/js/utils';

const twoColumnTextStyles = {
  base: {
    py: [6, null, 8],
    px: 4,
  },
  inner: {
    maxWidth: 1100,
    mx: 'auto',
  },
  left: {
    maxWidth: 530,
    mb: [5, null, 0],
  },
  headline: {
    pb: 2,
  },
  content: {
    variant: 'styles.p',
  },
  right: {
    maxWidth: 240,
    ml: [null, null, 'auto'],
  },
  cta: {
    mb: 3,
  },
  address: {
    variant: 'styles.pSmall',
    mb: 3,
  },
  contactInfo: {
    variant: 'styles.pSmall',
  },
  link: {
    color: 'textInverted',
    textDecoration: 'none',
  },
};

const TwoColumnTextComp = (props) => {
  const {
    pageBody: { bgColor, _rawTwoColTextLeftCol, _rawTwoColTextRightCol, textTheme },
  } = props;
  return (
    <Box sx={{ ...twoColumnTextStyles.base, ...{ backgroundColor: bgColor } }}>
      <Grid gap="15px" columns={[1, null, 2]} sx={{ ...twoColumnTextStyles.inner, ...{ color: textTheme === 'light' ? 'white' : 'black' } }}>
        <Box sx={twoColumnTextStyles.left}>
          {_rawTwoColTextLeftCol && (
            <Box>
              {_rawTwoColTextLeftCol?.heading && (
                <Box sx={twoColumnTextStyles.headline}>
                  <ThemeHeader.H2>{_rawTwoColTextLeftCol?.heading}</ThemeHeader.H2>
                </Box>
              )}
              {_rawTwoColTextLeftCol?.description && (
                <Box sx={twoColumnTextStyles.content}>
                  <BlockContent blocks={_rawTwoColTextLeftCol?.description} serializers={serializers} />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box sx={twoColumnTextStyles.right}>
          {_rawTwoColTextRightCol?.bookNow && (
            <Box sx={twoColumnTextStyles.cta}>
              <CTALink key={_rawTwoColTextRightCol?.bookNow._key} linkData={_rawTwoColTextRightCol?.bookNow} />
            </Box>
          )}
          {_rawTwoColTextRightCol?.address && (
            <Box sx={twoColumnTextStyles.address}>
              <AnchorLink target="__blank" href={mapLocationURL} sx={twoColumnTextStyles.link}>
                <BlockContent blocks={_rawTwoColTextRightCol.address} serializers={serializers} />
              </AnchorLink>
            </Box>
          )}
          {_rawTwoColTextRightCol?.contactNo && (
            <Box sx={twoColumnTextStyles.contactInfo}>
              <AnchorLink href={`tel:${_rawTwoColTextRightCol.contactNo}`}>{_rawTwoColTextRightCol.contactNo}</AnchorLink>
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default TwoColumnTextComp;
