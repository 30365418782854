import { alpha } from '@theme-ui/color';
import * as FontFaces from '../fonts/FontFaces';
import * as colors from './colors';
import * as styles from './styles';
import * as layout from './variant/layout';
import * as text from './variant/text';

export default {
  // We aren't using color modes, so we disable the local storage
  // mechanism, which only helps optimize color mode
  // loading when the color mode is user-chosen.
  useLocalStorage: false,

  colors: {
    text: colors.BLACK,
    textInverted: colors.WHITE,
    textDisabled: colors.BLACK,
    background: colors.WHITE,
    backgroundInverted: colors.BLACK,
    backgroundDisabled: colors.GRAY,
    backgroundOverlay: alpha(colors.BLACK, 0.5)({}),
    primary: colors.NAVY,
    secondary: colors.GREEN,
    accent: colors.CREAM,
    muted: colors.GRAY,
    mediaCaption: colors.BLACK,
    logo: colors.BLACK,
    error: colors.RED,
    mobileMenu: colors.PINK,
  },

  fonts: {
    body: String(FontFaces.BrandonGrotesqueRegular),
    heading: String(FontFaces.AustinRoman),
  },

  //         [0   1   2   3   4   5   6   7   8   9]
  fontSizes: [10, 12, 14, 16, 18, 24, 36, 48, 56, 72],

  fontWeights: {
    light: 300,
    body: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
    heading: 300,
  },

  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },

  //              [0   1   2     3   4  5   6   7]
  letterSpacings: [-3, -2, -1, -0.5, 0, 1, 1.5, 2],

  //     [0  1  2   3   4   5   6   7    8    9   10]
  space: [0, 5, 10, 10, 15, 20, 30, 35, 45, 50, 60],
//  space: [0, 5, 10, 15, 30, 45, 60, 75, 90, 105, 120],

  sizes: {
    container: 1230, // NOTE: If this changes, the corresponding breakpoint must change, too.
  },

  // copy of default breakpoints so it comes up in useThemeUi
  // plus a breakpoint to match layout.container.maxWidth
  //           [0           1            2]
  breakpoints: ['640px', '1024px', '1400px'],

  // Global styles configurations
  // get value in sx from them i.e property: (theme) => `${theme.key.val}`
  stylesConfig: {
    hearderHeightDesktop: 138,
    hearderHeightMobile: 80,
    containerSpaceDesktop: '24px',
    containerSpaceMobile: '8px',
  },

  styles,
  layout,
  text,
};
